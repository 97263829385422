// --------------------------------------------------------------------------------------------------
// Carousel
// --------------------------------------------------------------------------------------------------
let currentQuoteId
const quotes = [
  {
    id: '11',
    headline: 'Modern Gate Management at STL',
    name: 'Cole Meyer, A.A.E.',
    title: 'Airport Assistant Director<br>of Airside Operations',
    organization: 'St. Louis Lambert International Airport',
    quote: 'Since implementing GoApron, our gate management has become much more streamlined and efficient. The platform offers real-time insights into gate usage, which not only improves scheduling but has also virtually eliminated revenue leakage from unauthorized or mismanaged gate use. With GoApron, we can track everything accurately, ensuring that all gate usage and remote parking is accounted for. Some additional features allow us to track diversions, manage gate usage during construction and ensure that only aircraft of the correct design group and type are allowed to use our common use gates. Their responsive customer service and customizable features make the system even more valuable. The time and resources we’ve saved, along with the added control over our operations, have made a huge difference for our team.',
  },
  {
    id: '1',
    headline: 'Billing at Birmingham',
    name: 'Korey Campbell',
    title: 'BHM Chief Financial Officer',
    organization: 'Birmingham-Shuttlesworth International Airport',
    quote: 'GoApron has been a wonderful asset for BHM. Now that we have buy-in from the airlines, scheduling and billing for our common use gates and RONs is much easier. The GoApron team is very responsive and has implemented requested changes that help us do our job more efficiently.',
  },
  {
    id: '2',
    headline: 'Scheduling made easy in Savannah',
    name: 'Andy Singhas, C.M.',
    title: 'SAV Senior Operations Manager',
    organization: 'Savannah/Hilton Head International Airport',
    quote: 'Gate planning for construction projects and fluctuating seasonal flight schedules had our heads spinning. GoApron has been a game changer for SAV. Everyone’s schedules, requests, and needs are in one easy-to-use place for all to see. Implementation and support have been superb!',
  },
  {
    id: '3',
    headline: 'Smarter Plane Parking in Wichita',
    name: 'Brian Cowles',
    title: 'Airport Operations Manager',
    organization: 'Wichita Airport Authority',
    quote: 'It’s been a pleasure working with GoApron for several reasons, including their easy to use product, amazing customer service, and continuous product updates. Before GoApron, we would spend countless hours trying to manage our gates and RON spots with our manual process. GoApron automates the data for gate assignments and helps ICT park planes smarter. Working with GoApron has been seamless for our airport and airline partners, we look forward to growing with them.',
  },
  {
    id: '4',
    headline: 'Creating Seamless Solutions in Spokane',
    name: 'Jennifer Keyen',
    title: 'Operations Specialist',
    organization: 'Spokane International Airport',
    quote: 'GoApron offers solutions for otherwise longer procedures as it pertains to gate and ramp management. Reservations are completed in a matter of minutes, from request to approval thereby saving time and doing away with paper trails and possible miscommunication. I have so far enjoyed the ease of using GoApron as almost all the data required is inbuilt and will auto-populate. Working with the administrators has been seamless and I look forward to more developments as we continue to work together.',
  },
  {
    id: '5',
    headline: 'Easing Gate Assignments in Boise',
    name: 'Scott Gwiazda',
    title: 'Airport Operations Manager',
    organization: 'Boise Airport',
    quote: 'GoApron has really organized and streamlined our gate management. It has made assigning the limited gates at the airport easier for all involved. It is very easy to use for both airlines and the airport. The staff at GoApron is extremely helpful for any type of inquiry we present to them. They are also constantly working to add more features to GoApron that will make it easier to use and provide more detailed information.',
  },
  {
    id: '6',
    headline: 'Effortless Adoption in Spokane',
    name: 'Joshua Rykken',
    title: 'Operations Manager',
    organization: 'Trego-Dugan Aviation',
    quote: 'GoApron is an easy to use scheduling tool. The GoApron team has worked side by side with us since the beginning to make sure we got the tool we wanted! We could not be more pleased with the ease and functionality GoApron offers.',
  },
  {
    id: '7',
    headline: 'Meeting Operations Needs in Spokane',
    name: 'Rachel Jordan',
    title: 'Station Manager',
    organization: 'GEG Horizon Air Ops',
    quote: 'It is pretty simple to use and it has worked just fine for Horizon’s aircraft parking needs.',
  },
  {
    id: '8',
    headline: 'MKE Gate Management Goes Digital with GoApron',
    name: 'Ryan Collins',
    title: 'Director of Operations & Maintenance',
    organization: 'Milwaukee Mitchell International Airport',
    quote: 'We took a laborious process and turn it into something very streamlined.',
  },
  {
    id: '9',
    headline: 'Real-Time Business Intelligence at MKE',
    name: 'Brian Dranzik',
    title: 'Airport Director',
    organization: 'Milwaukee Mitchell International Airport',
    quote: 'This technology gives us real-time business intelligence and increased accuracy while reducing staff workload.',
  },
  {
    id: '10',
    headline: 'Improving Relationships in Glacier Park',
    name: 'Ian Mckay',
    title: 'Deputy Director',
    organization: 'Glacier Park International Airport',
    quote: 'We struggled creating a system that would allow us to make the best use of our gates as a recourse that was easy to use and could get the buy-in we needed from our stakeholders. As a highly seasonal airport, our flights are often scheduled within minutes of one another, so it takes the entire team weeks of planning and preparation to keep the operation running smoothly. GoApron has helped us simplify the process of disseminating information and coordinate the use of our gates. This benefit extends beyond the operational component by bringing our stakeholders closer together and improving relationships. I look forward to seeing the product develop and continuing to put it to good use.',
  },
]

const loadQuote = (quoteId) => {
  // console.log('')
  // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  // console.log('carousel.js - loadQuote - quoteId: ', quoteId)
  // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  // console.log('')

  currentQuoteId = quoteId.toString()

  // console.log('')
  // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  // console.log('carousel.js - loadQuote - currentQuoteId: ', currentQuoteId)
  // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  // console.log('')

  const quoteElement = document.querySelector('#testimonials blockquote q')
  const headlineElement = document.querySelector('#testimonials header h3')
  const nameElement = document.querySelector('#testimonials .name')
  const titleElement = document.querySelector('#testimonials .title')
  const organizationElement = document.querySelector('#testimonials .organization')
  const controls = document.querySelectorAll('#testimonials #controls .control')

  // console.log('')
  // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  // console.log('carousel.js - loadQuote - quoteElement: ', quoteElement)
  // console.log('--------------------------------------')
  // console.log('carousel.js - loadQuote - nameElement: ', nameElement)
  // console.log('--------------------------------------')
  // console.log('carousel.js - loadQuote - titleElement: ', titleElement)
  // console.log('--------------------------------------')
  // console.log('carousel.js - loadQuote - organizationElement: ', organizationElement)
  // console.log('--------------------------------------')
  // console.log('carousel.js - loadQuote - controls: ', controls)
  // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  // console.log('')

  // eslint-disable-next-line no-unused-vars
  controls.forEach((control, index) => {
    // console.log('')
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('carousel.js - loadQuote - control: ', control)
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('')

    if (control.getAttribute('data-quote-id') !== quoteId.toString()) {
      control.classList.remove('active')
    } else {
      control.classList.add('active')
    }
  })

  // console.log('')
  // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  // console.log('carousel.js - loadQuote - quotes: ', quotes)
  // console.log('--------------------------------------')
  // console.log('carousel.js - loadQuote - quotes[1].id: ', quotes[1].id)
  // console.log('carousel.js - loadQuote - quoteId: ', quoteId)
  // console.log('--------------------------------------')
  // console.log('carousel.js - loadQuote - (quotes[1].id.toString() === quoteId.toString()): ', (quotes[1].id.toString() === quoteId.toString()))
  // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  // console.log('')

  const quoteData = quotes.find(quote => quote.id.toString() === quoteId.toString())
  // console.log('')
  // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  // console.log('carousel.js - loadQuote - quoteData: ', quoteData)
  // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  // console.log('')

  quoteElement.innerHTML = quoteData.quote
  headlineElement.innerHTML = quoteData.headline
  nameElement.innerHTML = quoteData.name
  titleElement.innerHTML = quoteData.title
  organizationElement.innerHTML = quoteData.organization
}

// --------------------------------------------------------------------------------------------------
// "Ready"
// --------------------------------------------------------------------------------------------------
// eslint-disable-next-line no-unused-vars
const init = (event = null) => {
  // console.log('carousel.js - init')

  const quotesContainer = document.querySelector('#testimonials')
  // const randomQuote = Math.round(Math.random() * 5 + 1)

  // console.log('')
  // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  // console.log('carousel.js - init - quotesContainer: ', quotesContainer)
  // // console.log('carousel.js - init - randomQuote: ', randomQuote)
  // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
  // console.log('')

  if (quotesContainer) {
    const controlsContainer = document.querySelector('#testimonials #controls #control-btn-container')
    const previousQuoteButton = document.querySelector('#testimonials #controls #prev-quote')
    const nextQuoteButton = document.querySelector('#testimonials #controls #next-quote')

    // console.log('')
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('carousel.js - init - previousQuoteButton: ', previousQuoteButton)
    // console.log('carousel.js - init - nextQuoteButton: ', nextQuoteButton)
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('')

    // eslint-disable-next-line no-unused-vars
    previousQuoteButton.addEventListener('click', (event) => {
      if (currentQuoteId === quotes[0].id) {
        loadQuote(quotes[quotes.length - 1].id)
      } else {
        const currentQuoteIndex = quotes.findIndex(quoteData => quoteData.id === currentQuoteId)
        // console.log('')
        // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
        // console.log('carousel.js - DOMContentLoaded - previousQuoteButton - currentQuoteIndex: ', currentQuoteIndex)
        // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
        // console.log('')

        loadQuote(quotes[currentQuoteIndex - 1].id)
      }
    })

    // eslint-disable-next-line no-unused-vars
    nextQuoteButton.addEventListener('click', (event) => {
      if (currentQuoteId === quotes[quotes.length - 1].id) {
        loadQuote(quotes[0].id)
      } else {
        const currentQuoteIndex = quotes.findIndex(quoteData => quoteData.id === currentQuoteId)
        // console.log('')
        // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
        // console.log('carousel.js - DOMContentLoaded - nextQuoteButton - currentQuoteIndex: ', currentQuoteIndex)
        // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
        // console.log('')

        loadQuote(quotes[currentQuoteIndex + 1].id)
      }
    })

    // console.log('')
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('carousel.js - init - quotes: ', quotes)
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
    // console.log('')

    quotes.forEach((data, index) => {
      // console.log('')
      // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
      // console.log('carousel.js - init - quotes.forEach - data: ', data)
      // console.log('carousel.js - init - quotes.forEach - index: ', index)
      // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
      // console.log('')

      const control = document.createElement('div')
      control.setAttribute('data-quote-id', data.id)
      control.addEventListener('click', (event) => {
        const quoteId = event.target.getAttribute('data-quote-id')
        // console.log('quoteId: ', quoteId)

        loadQuote(quoteId)
      })

      // console.log('')
      // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
      // console.log('carousel.js - init - quotes.forEach - control: ', control)
      // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
      // console.log('')

      if (index === 0) {
        control.setAttribute('class', 'control active')
        loadQuote(data.id)
      } else {
        control.setAttribute('class', 'control')
      }

      // console.log('')
      // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
      // console.log('carousel.js - init - quotes.forEach - control (FINAL): ', control)
      // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
      // console.log('')

      controlsContainer.appendChild(control)
    })
  } else {
    console.warn('carousel.js - `quotesContainer` could not be found.')
  }
}

if (document.readyState !== 'loading') {
  init()
} else {
  document.addEventListener('DOMContentLoaded', init)
}
